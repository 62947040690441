import { Form, Input, Button, Row, Col } from "antd";
import { useState } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import { useToast } from "~/utils";
import { NoopLayout } from "~/layouts/noop";
import { newAuthRepository } from "~/repositories/auth";
import { client } from "~/api/client";
import { SignInInput } from "~/types/auth";

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const SignInPage = () => {
  const router = useRouter();
  const repository = newAuthRepository(client);

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onFinish = async (data: SignInInput) => {
    setLoading(true);
    try {
      const response = await repository.signIn(data);
      const authHeaders = {
        "access-token": response["access-token"],
        client: response["client"],
        expiry: response["expiry"],
        uid: response["uid"],
      };
      await axios.post("/api/signin", {
        authHeaders: JSON.stringify(authHeaders),
      });
    } catch (e) {
      const { code } = e;
      switch (code) {
        default:
          Sentry.captureException(e);
          toast.error(
            "エラーが発生しました。お手数ですがもう一度時間を開けておためしください"
          );
          break;
      }
      return;
    } finally {
      setLoading(false);
    }
    await router.replace(
      router.query.redirect_to ? (router.query.redirect_to as string) : "/"
    );
  };

  const onFinishFailed = (e) => {
    Sentry.captureException(e);
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Col span={12}>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout={"vertical"}
          >
            <Form.Item
              label="メールアドレス"
              name="email"
              rules={[
                { required: true, message: "メールアドレスを入力してください" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="パスワード"
              name="password"
              rules={[
                { required: true, message: "パスワードを入力してください" },
              ]}
            >
              <Input.Password autoComplete={"false"} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={loading} block>
                ログイン
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

SignInPage.Layout = NoopLayout;

export default SignInPage;
