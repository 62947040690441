import { useEffect, useState } from "react";
import { Notyf } from "notyf";

export const useToast = () => {
  const [toast, setToast] = useState(null);
  useEffect(() => {
    setToast(
      new Notyf({
        duration: 3000,
        position: { x: "center", y: "top" },
      })
    );
  }, []);
  return toast;
};
