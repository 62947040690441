import { AxiosInstance } from "axios";
import { SignInInput, Headers, AuthHeaders } from "~/types/auth";
import { User } from "~/types/user";

export interface AuthRepository {
  signIn(input: SignInInput): Promise<Headers>;
  getMe(headers: AuthHeaders): Promise<User>;
}

class AuthRepositoryImpl implements AuthRepository {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async signIn(input: SignInInput): Promise<Headers> {
    const { data, headers } = await this.client.post(
      "/api/v1/auth/sign_in",
      input
    );
    if (data.super_admin === false) {
      throw new Error("Permission denied");
    }
    return headers;
  }

  async getMe(headers: AuthHeaders): Promise<User> {
    const { data } = await this.client.get("/api/v1/me", {
      headers,
    });
    return data;
  }
}

export const newAuthRepository = (client: AxiosInstance): AuthRepository => {
  return new AuthRepositoryImpl(client);
};
